module controllers {
    export module applicationcore {
        interface IDashboardScope extends ng.IScope {
        }

        export class dashboardCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', "dashboardService", "bsLoadingOverlayService", "$state","$interval",'$cookies'];

            reports: interfaces.applicationcore.INVD3Dashboard[] = [];

            dashboards: interfaces.applicationcore.IDropdownModel[] = [];

            dashboardId: number;

            IsLoading: boolean = false;
            
            constructor(private $scope: IDashboardScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService, private $q: ng.IQService,
                private dashboardService: interfaces.applicationcore.IDashboardService,
                private bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                public $interval: ng.IIntervalService,
                private $cookies: ng.cookies.ICookiesService) {
                this.loadDashboards();
                this.loadUserDashboard();

                $scope.$on('$destroy', () => {
                    for (let dashboardIntervalPromise of this.dashboardIntervalPromises) {
                        this.$interval.cancel(dashboardIntervalPromise.intervalPromise);
                    }
                    this.dashboardIntervalPromises = [];
                });

            }

            loadDefaultAutoRefreshIntervalTimers(){

                for (let dashboardIntervalPromise of this.dashboardIntervalPromises) {
                    this.$interval.cancel(dashboardIntervalPromise.intervalPromise);                    
                }

                this.dashboardIntervalPromises = [];

                angular.forEach(this.reports, (Value, DashboardId) => {

                    let dbId = Value.DashboardId;
                    var report = _.find(this.reports, function (x) { return x.DashboardId == dbId });
                    var refreshInterval = report.RefreshInterval? report.RefreshInterval*60000 : 5 * 60000;

                    if (report.AutoRefresh)
                    {
                        var dashboardAutoRefreshPromise = this.$interval(() => {return this.refreshReport(report,0)},refreshInterval);
                        this.dashboardIntervalPromises.push({dashboardId:report.DashboardId,intervalPromise:dashboardAutoRefreshPromise});
                    }
                });
            }

            loadUserDashboard() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'dashboard'
                },
                    () => {
                        return this.dashboardService.nvd3Dashboard().query((result) => {
                            
                            this.reports = result;
                            this.loadDashboardClickEvents();
                            this.loadDefaultAutoRefreshIntervalTimers();
                            this.loadDashboardFiltersFromCookieStorage();

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            loadDashboardFiltersFromCookieStorage(){
              
                angular.forEach(this.reports, (Value, DashboardId) => {
                    let dbId = Value.DashboardId;
                 
                    var reportFilterString = this.$cookies.get('report-'+Value.DashboardId);
                    if (reportFilterString){
                        var Filter =JSON.parse(reportFilterString);
                        
                        if (Filter){
                                _.forEach(Filter, (filter:interfaces.applicationcore.IDashboardFilter)=>{
                                    if (filter.FilterValueDate){
                                       
                                        filter.FilterValueDate = moment(filter.FilterValueDate);
                                    }
                                });

                            Value.Filter = Filter;
                            this.refreshReport(Value,0);
                        }
                        
                    }
                });
            }

            loadDashboardClickEvents() {

                angular.forEach(this.reports, (Value, DashboardId) => {

                    let dbId = Value.DashboardId;

                    switch (Value.Options.chart.type) {
                        case "pieChart":

                            Value.Options.chart.callback = (chart) => {

                                chart.pie.dispatch.on('elementMouseover', (e) => {

                                    var currentReport = _.find(this.reports, function (x) { return x.DashboardId == dbId });

                                    //only change cursor if we are redirecting to a List View

                                    if (currentReport.ListViewState) {
                                        var id: string = "#report" + dbId + " svg";
                                        var svg = document.querySelector(id);
                                            d3.selectAll(svg.getElementsByClassName("nv-slice")).style("cursor", "pointer"); 
                                    }

                                });
                                
                                chart.pie.dispatch.on('elementClick', (e) => {
                                    
                                   

                                    var currentReport = _.find(this.reports, function (x) { return x.DashboardId == dbId });

                                    var filterList: interfaces.applicationcore.IKeyValue[] = [];
                                    var searchList: interfaces.applicationcore.IKeyValue[] = [];                                   
                                    
                                    if (e.data.keyId)
                                    {
                                        searchList.push({  key: currentReport.DataKeyListFilter, value: e.data.keyId});
                                    }
                                    else
                                    {
                                        filterList.push({ key: currentReport.DataKeyListFilter, value: e.data.key });
                                    }
                                    

                                    angular.forEach(currentReport.Filter, (rep, DashboardId) => {

                                        if (rep.ControlType === 0) {
                                            searchList.push({ key: rep.ListViewFilterName, value: rep.FilterValueText });
                                        }

                                        if (rep.ControlType === 1) {
                                            searchList.push({ key: rep.ListViewFilterName, value: rep.FilterValueNumeric });
                                        }

                                        if (rep.ControlType === 2) {
                                            searchList.push({ key: rep.ListViewFilterName, value: rep.FilterValueDate });
                                        }

                                        if (rep.ControlType === 3) {
                                            searchList.push({ key: rep.ListViewFilterName, value: Number(rep.FilterValueText) });
                                        }

                                    });

                                    this.$state.go(currentReport.ListViewState, { FilterList: filterList, SearchList: searchList });

                                });


                            }

                            break;

                        case "lineChart":


                            break;
                        case "discreteBarChart":

                            break;

                        case "multiBarChart":

                            Value.Options.chart.callback = (chart) => {

                                chart.multibar.dispatch.on('elementMouseover', (e) => {

                                    var currentReport = _.find(this.reports, function (x) { return x.DashboardId == dbId });

                                    //only change cursor if we are redirecting to a List View

                                    if (currentReport.ListViewState) {
                                        var id: string = "#report" + dbId + " svg";
                                        var svg = document.querySelector(id);                                        
                                        d3.selectAll(svg.getElementsByClassName("nv-group")).style("cursor", "pointer");
                                    }

                                });


                                chart.multibar.dispatch.on('elementClick', (e) => {

                                    var currentReport = _.find(this.reports, function (x) { return x.DashboardId == dbId });

                                    var filterList: interfaces.applicationcore.IKeyValue[] = [];
                                    var searchList: interfaces.applicationcore.IKeyValue[] = [];

                                    //filterList.push({ key: currentReport.DataKeyListFilter, value: e.data.key });
                                    if (e.series.keyId)
                                    {
                                        searchList.push({  key: currentReport.DataKeyListFilter, value: e.series.keyId});
                                    }
                                    else
                                    {
                                        filterList.push({ key: currentReport.DataKeyListFilter, value: e.data.key });
                                    }

                                    if (currentReport.UseDataLabelAsAdvancedSearchFilter)
                                    {
                                        searchList.push({  key: currentReport.DataLabelListFilter, value: e.data.label});
                                    }
                                    else
                                    {
                                        filterList.push({ key: currentReport.DataLabelListFilter, value: e.data.label });
                                    }

                                    angular.forEach(currentReport.Filter, (rep, DashboardId) => {

                                        if (rep.ControlType === 0) {
                                            searchList.push({ key: rep.ListViewFilterName, value: rep.FilterValueText });
                                        }

                                        if (rep.ControlType === 1) {
                                            searchList.push({ key: rep.ListViewFilterName, value: rep.FilterValueNumeric });
                                        }

                                        if (rep.ControlType === 2) {
                                            searchList.push({ key: rep.ListViewFilterName, value: rep.FilterValueDate });
                                        }

                                        if (rep.ControlType === 3) {
                                            searchList.push({ key: rep.ListViewFilterName, value: Number(rep.FilterValueText) });
                                        }

                                    });

                                    this.$state.go(currentReport.ListViewState, { FilterList: filterList, SearchList: searchList });

                                });


                            }

                            break;

                        default:

                            break;
                    }

                });
            }

            addReport() {
                this.reports.push(<interfaces.applicationcore.INVD3Dashboard>{});
            }

            removeReport(report: interfaces.applicationcore.INVD3Dashboard, index: number) {
                if (this.reports && report && report.DashboardId > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'dashboard'
                    },
                        () => {
                            return this.dashboardService.nvd3Dashboard().delete({
                                dashboardId: report.DashboardId
                            }, (result: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(result);
                                this.reports.splice(index, 1);
                            }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                        });
                } else {
                    this.reports.splice(index, 1);
                }
            }

            loadDashboards() {
                return this.dashboardService.dashboardDropdown().query((result) => {
                    this.dashboards = result;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            takePicture(DashboardId: number)
            {
                var currentReport = _.find(this.reports, function (x) { return x.DashboardId == DashboardId });
                if (currentReport.gtsDirective)
                {
                    var display = _.find(this.dashboards, function (x) { return x.Id == DashboardId });

                    var doc = document.querySelector("#report" + DashboardId );

                    var height = doc.getBoundingClientRect().height;
                    var width =  doc.getBoundingClientRect().width;

                    var container = doc.querySelector("#overflowdiv");
                    if (container)
                    {
                        height = container.scrollHeight+100;
                        (container as HTMLElement).style.overflow = "visible";
                        var clonedContainer = (container as HTMLElement).cloneNode(true);
                        (clonedContainer as HTMLElement).style.overflow = "visible";
                    }
                    
                    var myOffset = this.offset(doc);
                   

                    html2canvas(/*document.querySelector("#report" + DashboardId)*/ (doc as HTMLElement),{width:width,height:height,x:myOffset.left,y:myOffset.top}).then(canvas => {
                        canvas.toBlob(function(blob) {
                                                      
                            if (container){
                                (container as HTMLElement).style.overflow = "auto";
                            }
                           
                            var pngDownload = document.createElement("a");
                            document.body.appendChild(pngDownload);

                            var fileBlob = new Blob([blob as BlobPart], { type: 'image/octet-stream' });

                            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                                window.navigator.msSaveBlob(fileBlob,  display.Display + ".png");
                            }
                            else
                            {                            
                             var   url = URL.createObjectURL(fileBlob);
                                pngDownload.href = url;
                                pngDownload.download = display.Display + ".png";
                                pngDownload.click();
                                pngDownload.remove();
                            }
                          });

                    });
                }
                else
                {
                    var id: string = "#report" + DashboardId + " svg";
                    var svg = document.querySelector(id);
                    var display = _.find(this.dashboards, function (x) { return x.Id == DashboardId });
                    saveSvgAsPng(svg, display.Display + ".png");
                }             
            }

            offset(el) {
                var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            }

            saveAsPng(response,filename)
            {
                var pngDownload = document.createElement("a");
                document.body.appendChild(pngDownload);

                var fileBlob = new Blob([response as BlobPart], { type: 'image/octet-stream' });

                if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                    window.navigator.msSaveBlob(fileBlob, filename);
                } else { // for other browsers
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    pngDownload.href = fileURL;
                    pngDownload.download = filename;
                    pngDownload.click();
                    pngDownload.remove();
                }
            }

            saveAs(uri, filename) {
                var link = document.createElement('a');
                if (typeof link.download === 'string') {
                  link.href = uri;
                  link.download = filename;
            
                  //Firefox requires the link to be in the body
                  document.body.appendChild(link);
            
                  //simulate click
                  link.click();
            
                  //remove the link when done
                  document.body.removeChild(link);
                } else {
                  window.open(uri);
                }
              }

            isIE() : boolean {
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    return false;
                } else {
                    return true;
                }
            }

            reportSelected(report: interfaces.applicationcore.INVD3Dashboard, index: number) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'dashboard'
                },
                    () => {
                        return this.saveReport(report, index);
                    });
            }

            saveReport(report: interfaces.applicationcore.INVD3Dashboard, index: number) {
                return this.dashboardService.nvd3Dashboard().save({
                    dashboardId: report.DashboardId
                }, {}, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);
                    this.loadUserDashboard();
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        report.DashboardId = undefined;
                    }).$promise;
            }



            refreshReport(report: interfaces.applicationcore.INVD3Dashboard, index: number)
            {

                var searchObject: interfaces.applicationcore.IFilterSearchObject = {
                    filters: []
                }

                if (this.reports && report && report.DashboardId > 0)
                {
                    angular.forEach(report.Filter, (Value, ControlType) => {

                        if (Value.ControlType === 0) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueText });
                        }

                        if (Value.ControlType === 1) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueNumeric });
                        }

                        if (Value.ControlType === 2 ) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueDate });
                        }

                        if (Value.ControlType === 3) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueText });
                        }

                    });

                    searchObject.filters.push({ Name: "CurrentyEntityId", Value: this.$rootScope.Entity.Id });

                    this.$cookies.put('report-'+report.DashboardId.toString(),JSON.stringify(report.Filter));

                   return this.GetData(report.DashboardId, searchObject,report.AutoRefresh);
                }

                
              

            }
            
            

            private dashboardIntervalPromises:interfaces.applicationcore.IDashboardTimerPromise[] = [];

            EnableDisableIntervalChange(report: interfaces.applicationcore.INVD3Dashboard )
            {
                var refreshInterval = report.RefreshInterval? report.RefreshInterval*60000 : 5 * 60000;
                
                if (report.AutoRefresh)
                {
                    var dashboardAutoRefreshPromise = this.$interval(() => {return this.refreshReport(report,0)},refreshInterval);
                    this.dashboardIntervalPromises.push({dashboardId:report.DashboardId,intervalPromise:dashboardAutoRefreshPromise})
                }
                else
                {
                    var dashboardIntervalPromise = this.dashboardIntervalPromises.filter( data => { return data['dashboardId'] === report.DashboardId})                    

                    if (dashboardIntervalPromise) {
                        this.$interval.cancel(dashboardIntervalPromise[0].intervalPromise);
                        var index = this.dashboardIntervalPromises.indexOf(dashboardIntervalPromise[0]);
                        if (index>=0)
                        {
                            this.dashboardIntervalPromises.splice(index,1);
                        }
                    }
                }
            }

            getDashboardReport(report: interfaces.applicationcore.INVD3Dashboard)
            {
                var searchObject: interfaces.applicationcore.IFilterSearchObject = {
                    filters: []
                }

                if (this.reports && report && report.DashboardId > 0)
                {
                    angular.forEach(report.Filter, (Value, ControlType) => {

                        if (Value.ControlType === 0) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueText });
                        }

                        if (Value.ControlType === 1) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueNumeric });
                        }

                        if (Value.ControlType === 2 ) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueDate });
                        }

                        if (Value.ControlType === 3) {
                            searchObject.filters.push({ Name: Value.FilterName, Value: Value.FilterValueText });
                        }

                    });

                    searchObject.filters.push({ Name: "CurrentyEntityId", Value: this.$rootScope.Entity.Id });

                    this.GetReport(report.reportCode, searchObject);
                }
            }

            GetReport(reportCode:string,searchObject:interfaces.applicationcore.IFilterSearchObject )
            {
                return this.dashboardService.getDashboardReport(reportCode, searchObject).then(() => {
                }, (data) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            GetData(dashboardId: number, connectSearch: interfaces.applicationcore.IFilterSearchObject, autoRefresh:boolean) {
                this.IsLoading = true;

                var params = {
                    dashboardId: dashboardId,
                    connectSearch: connectSearch
                }
              
                return this.dashboardService.getData(dashboardId, connectSearch).query((data: interfaces.applicationcore.INVD3Dashboard) => {
                    data.DashboardId = dashboardId;
                    data.Filter = this.reports.filter(x => x.DashboardId == dashboardId)[0].Filter;
                    data.AutoRefresh = autoRefresh;
                    this.reports = this.reports.map((item) => { return item.DashboardId == dashboardId ? data : item; })
                    this.IsLoading = false;
                    this.loadDashboardClickEvents();

                }, (data: interfaces.applicationcore.INVD3Dashboard) => {
                    this.IsLoading = false;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }
                
            
        }

        angular.module("app").controller("dashboardCtrl", controllers.applicationcore.dashboardCtrl);
    }
}